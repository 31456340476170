import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getPaginationState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faCopy } from '@fortawesome/free-solid-svg-icons';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getAllEntities as getAllProgetti } from 'app/entities/progetti/progetti.reducer';

import { deleteEntity, getEntities, getEntities as getTimbature } from './timbrature.reducer';
import { calculateExpectedWorkHours } from 'app/shared/components/festivita';
import { duplicateEntity } from './timbrature.reducer';
import moment from 'moment';
import 'moment/locale/it'; // Importa la localizzazione italiana

export const Timbrature = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [isReady, setIsReady] = useState(false);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const timbratureList = useAppSelector(state => state.timbrature.entities);
  const loading = useAppSelector(state => state.timbrature.loading);
  const totalItems = useAppSelector(state => state.timbrature.totalItems);
  const progettiLista = useAppSelector(state => state.progetti.entities);
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1); // Imposta il mese corrente come predefinito
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear()); // Imposta il mese corrente come predefinito
  const [expectedHours, setExpectedHours] = useState<number>(0);

  const months = [
    { value: 1, label: 'Gennaio' },
    { value: 2, label: 'Febbraio' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Aprile' },
    { value: 5, label: 'Maggio' },
    { value: 6, label: 'Giugno' },
    { value: 7, label: 'Luglio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Settembre' },
    { value: 10, label: 'Ottobre' },
    { value: 11, label: 'Novembre' },
    { value: 12, label: 'Dicembre' },
  ];

  const years = [
    { value: 2024, label: '2024' },
    { value: 2025, label: '2025' },
    { value: 2026, label: '2026' },
    { value: 2027, label: '2027' },
    { value: 2028, label: '2028' },
    { value: 2029, label: '2029' },
    { value: 2030, label: '2030' },
    { value: 2031, label: '2031' },
    { value: 2032, label: '2032' },
    { value: 2033, label: '2033' },
  ];
  const selectedMonthLabel = months.find(month => month.value === selectedMonth)?.label; // Ottieni il nome del mese selezionato

  const getAllEntities = async () => {
    await dispatch(
      getTimbature({
        month: selectedMonth,
        year: selectedYear,
      }),
    );
    await dispatch(
      getAllProgetti({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );

    setIsReady(true);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?&month=${selectedMonth}&year=${selectedYear}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };
  const handleMonthChange = e => {
    const month = parseInt(e.target.value, 10); // Ottieni il mese selezionato dal dropdown
    setSelectedMonth(month); // Imposta il nuovo mese selezionato
  };

  const handleYearChange = e => {
    const year = parseInt(e.target.value, 10); // Ottieni il mese selezionato dal dropdown
    setSelectedYear(year); // Imposta il nuovo mese selezionato
  };

  /*   const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
*/

  // calcolo ore attese per mese
  useEffect(() => {
    const hours = calculateExpectedWorkHours(selectedYear, selectedMonth);
    setExpectedHours(hours);
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, selectedMonth, selectedYear]);

  const getProgettoTitoloById = id => {
    const progetto = progettiLista.find(p => p.id === id); // Cerca il progetto con l'ID specifico
    return progetto ? progetto.titolo : 'Titolo non trovato'; // Restituisce il titolo o un messaggio di fallback
  };

  const totalHours = timbratureList.reduce((accumulator, currentValue) => accumulator + currentValue.ore, 0);
  const totalHoursColor = totalHours === expectedHours ? 'green' : 'red';
  const [newTimbraturaId, setNewTimbraturaId] = useState(null);

  const handleDuplicate = timbrature => {
    dispatch(duplicateEntity(timbrature)).then(resultAction => {
      if (duplicateEntity.fulfilled.match(resultAction)) {
        dispatch(getTimbature({ month: selectedMonth, year: selectedYear }));
        setNewTimbraturaId(resultAction.payload.data.id);
      }
    });
  };

  const oggi = moment().locale('it').format('LLLL');

  const getDayOfWeek = (dateString: string | null): string => {
    if (!dateString) return '';
    const date = moment(dateString);
    return date.locale('it').format('dddd');
  };

  return (
    <div>
      <h2 id="timbrature-heading" data-cy="TimbratureHeading">
        Report ore {selectedMonthLabel} {selectedYear}{' '}
      </h2>

      <div className="d-flex justify-content-center mb-5">
        <select onChange={handleMonthChange} value={selectedMonth}>
          {months.map(month => (
            <option key={month.value} value={month.value}>
              {month.label}
            </option>
          ))}
        </select>
        <select onChange={handleYearChange} value={selectedYear}>
          {years.map(year => (
            <option key={year.value} value={year.value}>
              {year.label}
            </option>
          ))}
        </select>

        <div className="me-2 ps-2">
          <Button className="me-2 ps-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="timeRepApp.timbrature.home.refreshListLabel">Aggiorna</Translate>
          </Button>

          <Link
            to="/timbrature/new"
            className="btn btn-primary jh-create-entity me-2 ps-2"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp; Inserisci nuovo report
          </Link>
        </div>
      </div>
      {/*       <h4 style={{ margin: 0, marginBottom: 30, color: '#1D1E40', fontWeight: 'bold' }}>Conteggio ore per utente</h4> */}

      {isReady && (
        <div className="table-responsive">
          {timbratureList && timbratureList.length > 0 ? (
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <h5 style={{ display: 'inline' }}>Giornate Attese per il mese corrente: </h5>
                  <h5 style={{ color: 'green', display: 'inline' }}>{expectedHours / 8}</h5>
                  <h5 style={{ display: 'inline' }}> - Ore Attese per il mese corrente: </h5>
                  <h5 style={{ color: 'green', display: 'inline' }}>{expectedHours}</h5>
                  <h5 style={{ display: 'inline' }}> - Ore inserite: </h5>
                  <h5 style={{ color: totalHoursColor, display: 'inline' }}>{totalHours}</h5>
                  <p style={{ marginBottom: 60, marginTop: 60 }}></p>
                  <Table responsive className="table-hover">
                    <thead>
                      <tr>
                        <th className="hand">
                          <Translate contentKey="timeRepApp.timbrature.data">Data</Translate>{' '}
                        </th>
                        <th className="hand">Giorno</th>
                        <th className="hand">
                          <Translate contentKey="timeRepApp.timbrature.ore">Ore</Translate>
                        </th>
                        <th className="hand">
                          <Translate contentKey="timeRepApp.timbrature.descrizione">Descrizione</Translate>{' '}
                        </th>
                        <th className="hand">
                          <Translate contentKey="timeRepApp.timbrature.giustificativo">Giustificativo</Translate>{' '}
                        </th>
                        <th className="hand">
                          <Translate contentKey="timeRepApp.timbrature.progetti">Progetti</Translate>
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {timbratureList &&
                        timbratureList.map((timb, i) => (
                          <tr key={`entity-${i}`} data-cy="entityTable">
                            <td
                              style={{ backgroundColor: i % 2 === 0 ? '#f4f4f4' : 'white' }}
                              className={timb.id === newTimbraturaId ? 'highlight' : ''}
                            >
                              {timb.data ? <TextFormat type="date" value={timb.data} format={APP_LOCAL_DATE_FORMAT} /> : null}
                            </td>
                            <td
                              style={{ backgroundColor: i % 2 === 0 ? '#f4f4f4' : 'white' }}
                              className={timb.id === newTimbraturaId ? 'highlight' : ''}
                            >
                              {getDayOfWeek(timb.data)}
                            </td>
                            <td
                              style={{ backgroundColor: i % 2 === 0 ? '#f4f4f4' : 'white' }}
                              className={timb.id === newTimbraturaId ? 'highlight' : ''}
                            >
                              {timb.ore}
                            </td>
                            <td
                              style={{ backgroundColor: i % 2 === 0 ? '#f4f4f4' : 'white' }}
                              className={timb.id === newTimbraturaId ? 'highlight' : ''}
                            >
                              {timb.descrizione}
                            </td>
                            <td
                              style={{ backgroundColor: i % 2 === 0 ? '#f4f4f4' : 'white' }}
                              className={timb.id === newTimbraturaId ? 'highlight' : ''}
                            >
                              {timb.giustificativo}
                            </td>
                            <td
                              style={{ backgroundColor: i % 2 === 0 ? '#f4f4f4' : 'white' }}
                              className={timb && timb.id && (timb.id === newTimbraturaId ? 'highlight' : '')}
                            >
                              {getProgettoTitoloById(timb.progetti.id)}
                            </td>
                            <td className="text-end" style={{ backgroundColor: i % 2 === 0 ? '#f4f4f4' : 'white' }}>
                              <div className="btn-group flex-btn-group-container">
                                <Button onClick={() => handleDuplicate(timb)} color="secondary" size="sm" data-cy="entityDuplicateButton">
                                  <FontAwesomeIcon icon={faCopy} /> <span className="d-none d-md-inline">Duplica +1</span>
                                </Button>
                                <Button
                                  tag={Link}
                                  to={`/timbrature/${timb.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                                  color="primary"
                                  size="sm"
                                  data-cy="entityEditButton"
                                >
                                  <FontAwesomeIcon icon="pencil-alt" />{' '}
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.edit">Edit</Translate>
                                  </span>
                                </Button>
                                <Button
                                  onClick={() =>
                                    (window.location.href = `/timbrature/${timb.id}/delete?sort=${paginationState.sort},${paginationState.order}&month=${selectedMonth}&year=${selectedYear}`)
                                  }
                                  color="danger"
                                  size="sm"
                                  data-cy="entityDeleteButton"
                                >
                                  <FontAwesomeIcon icon="trash" />{' '}
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.delete">Delete</Translate>
                                  </span>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          ) : (
            !loading && <div className="alert alert-warning">Nessun report trovato per il mese impostato</div>
          )}
        </div>
      )}

      {totalItems ? (
        <div className={timbratureList && timbratureList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Timbrature;

import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ITimbrature, defaultValue } from 'app/shared/model/timbrature.model';
import { IOreLavorate } from 'app/shared/model/oreLavorate.model';
import { IOreFerie } from 'app/shared/model/oreFerie.model';

const initialState: EntityState<IOreFerie> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/management/timbrature';

// Actions

export const findOreFerie = createAsyncThunk(
  'orelavorate/fetch_ore_Ferie',
  async ({ month, year }: IQueryParams) => {
    const requestUrl = `${apiUrl}/oreFerie?month=${month}&year=${year}`;
    return axios.get<IOreFerie[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const OreFerieSlice = createEntitySlice({
  name: 'oreFerie',
  initialState,
  extraReducers(builder) {
    builder

      .addMatcher(isFulfilled(findOreFerie), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(findOreFerie), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = OreFerieSlice.actions;

// Reducer
export default OreFerieSlice.reducer;

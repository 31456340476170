import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getPaginationState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getAllEntities as getAllProgetti } from 'app/entities/progetti/progetti.reducer';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { fetchAllTimbratures as getTimbature, reset as resetTimbrature } from './timbrature.reducer';
import { Row, Col } from 'reactstrap';
import { findOreLavorate } from 'app/management/timbrature/oreLavorate.reducer';
import { findOreMalattia } from 'app/management/timbrature/oreMalattia.reducer';
import { findOreFerie } from 'app/management/timbrature/oreFerie.reducer';
import * as XLSX from 'xlsx';
import { calculateExpectedWorkHours } from 'app/shared/components/festivita';

let isWhite = true;
const alternateWhiteAndGray = () => {
  if (isWhite) {
    isWhite = false;
    return 'white';
  } else {
    isWhite = true;
    return '#f4f4f4';
  }
};

export const ManagementTimbrature = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const oreListFerie = useAppSelector(state => state.oreferie.entities);
  const oreListMalattia = useAppSelector(state => state.oremalattia.entities);
  const oreList = useAppSelector(state => state.orelavorate.entities);
  const timbratureList = useAppSelector(state => state.timbrature.entities);
  const loading = useAppSelector(state => state.timbrature.loading);
  const totalItems = useAppSelector(state => state.timbrature.totalItems);
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1); // Imposta il mese corrente come predefinito
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear()); // Imposta il mese corrente come predefinito
  const [userColors, setUserColors] = useState({});
  const [expectedHours, setExpectedHours] = useState<number>(0);
  const [chartSize, setChartSize] = useState({ width: 630, height: 630 });

  const updateDimensions = () => {
    if (window.innerWidth < 1150) {
      // Cambia la larghezza del breakpoint a seconda delle tue esigenze
      setChartSize({ width: window.innerWidth * 0.8, height: window.innerWidth * 0.8 });
    } else {
      setChartSize({ width: 400, height: 400 });
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
      dispatch(resetTimbrature());
    };
  }, []);

  const months = [
    { value: 1, label: 'Gennaio' },
    { value: 2, label: 'Febbraio' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Aprile' },
    { value: 5, label: 'Maggio' },
    { value: 6, label: 'Giugno' },
    { value: 7, label: 'Luglio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Settembre' },
    { value: 10, label: 'Ottobre' },
    { value: 11, label: 'Novembre' },
    { value: 12, label: 'Dicembre' },
  ];
  const selectedMonthLabel = months.find(month => month.value === selectedMonth)?.label; // Ottieni il nome del mese selezionato

  const years = [
    { value: 2024, label: '2024' },
    { value: 2025, label: '2025' },
    { value: 2026, label: '2026' },
    { value: 2027, label: '2027' },
    { value: 2028, label: '2028' },
    { value: 2029, label: '2029' },
    { value: 2030, label: '2030' },
    { value: 2031, label: '2031' },
    { value: 2032, label: '2032' },
    { value: 2033, label: '2033' },
  ];

  // Conteggio ore per utente - esportazione in excel
  const exportToExcel = () => {
    // Creare un oggetto di dati vuoto
    const ore_consuntivate = [];
    const dettaglio_ore = [];
    const ore_per_progetto = [];

    // Aggiungere le intestazioni della tabella come prima riga
    const titolo = [`Ore consuntivate di ${selectedMonthLabel}_${selectedYear}`];
    const oreattese = [`Ore attese nel mese: ${expectedHours}`];
    const giorniattesi = [`Giornate attese nel mese: ${(expectedHours / 8).toFixed(1)}`];
    const headers = ['User', 'Giorni lavorati', 'Ore Lavorate', 'Ore Malattia', 'Ore Ferie', 'Totale'];
    ore_consuntivate.push(titolo);
    ore_consuntivate.push(oreattese);
    ore_consuntivate.push(giorniattesi);
    ore_consuntivate.push(headers);

    // instestazioni dettaglio ore
    const titolo2 = [`Dettagli ore per utente, progetto e giustificativo  ${selectedMonthLabel}_${selectedYear}`];
    const campi = ['Utente', 'Ore', 'Progetto', 'giustificativo'];
    dettaglio_ore.push(titolo2);
    dettaglio_ore.push(campi);

    // intestazione ore per progetto
    const titolo3 = [`Ore su progetto ${selectedMonthLabel}_${selectedYear}`];
    const campi2 = ['Ore', 'Progetto'];
    ore_per_progetto.push(titolo3);
    ore_per_progetto.push(campi2);

    // Aggiungere i dati della tabella
    oreList.forEach((oreItem, i) => {
      const giorniLavorati = (oreList[i].totOre / 8).toFixed(1);
      const row = [
        oreItem.createdBy,
        giorniLavorati,
        oreList[i].totOre,
        oreListMalattia[i] ? oreListMalattia[i].totOre : 0,
        oreListFerie[i] ? oreListFerie[i].totOre : 0,
        oreItem.totOre + (oreListMalattia[i] ? oreListMalattia[i].totOre : 0) + (oreListFerie[i] ? oreListFerie[i].totOre : 0),
      ];
      ore_consuntivate.push(row);
    });

    // dati a dettagli ore
    timbratureList.forEach((timbra, z) => {
      const row2 = [timbra.createdBy, timbra.totOre, timbra.titolo, timbra.giustificativo];
      dettaglio_ore.push(row2);
    });

    // dati ore su progetto
    data.forEach((orepro, y) => {
      const row3 = [orepro.name, orepro.value];
      ore_per_progetto.push(row3);
    });

    // Creare un foglio di lavoro
    const ws = XLSX.utils.aoa_to_sheet(ore_consuntivate);
    const ws2 = XLSX.utils.aoa_to_sheet(dettaglio_ore);
    const ws3 = XLSX.utils.aoa_to_sheet(ore_per_progetto);

    // Creare un libro di lavoro
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Ore Consuntivate');
    XLSX.utils.book_append_sheet(wb, ws2, 'Dettaglio Ore');
    XLSX.utils.book_append_sheet(wb, ws3, 'Ore Progetto');

    // Esporta il libro di lavoro in un file Excel
    XLSX.writeFile(wb, `Report_ore_${selectedMonthLabel}_${selectedYear}.xlsx`);
  };

  const getAllEntities = () => {
    dispatch(
      findOreLavorate({
        month: selectedMonth,
        year: selectedYear,
      }),
    );
    dispatch(
      findOreFerie({
        month: selectedMonth,
        year: selectedYear,
      }),
    );
    dispatch(
      findOreMalattia({
        month: selectedMonth,
        year: selectedYear,
      }),
    );
    dispatch(
      getTimbature({
        sort: `${paginationState.sort},${paginationState.order}`,
        month: selectedMonth,
        year: selectedYear,
      }),
    );
    dispatch(
      getAllProgetti({
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${paginationState.sort},${paginationState.order}&month=${selectedMonth}&year=${selectedYear}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const sort = params.get(SORT);
    if (sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handleSyncList = () => {
    sortEntities();
  };

  /*   useEffect(() => {
    console.log(expectedHours);
    console.log(calculateExpectedWorkHours(selectedYear, selectedMonth));
  }, [expectedHours]); */

  /*   const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  }; */

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, selectedMonth, selectedYear]);

  const orePerProgetto = {};
  timbratureList.forEach(timbratura => {
    const progettoId = timbratura.titolo;
    const ore = timbratura.totOre;
    if (!orePerProgetto[progettoId]) {
      orePerProgetto[progettoId] = 0;
    }
    orePerProgetto[progettoId] += ore;
  });

  // Converte l'oggetto in un array di oggetti con chiave e valore
  const data = Object.keys(orePerProgetto).map(progettoId => ({
    name: progettoId, // Utilizza l'ID del progetto come etichetta
    value: orePerProgetto[progettoId], // Ore dedicate al progetto
  }));

  data.sort((a, b) => b.value - a.value);

  // Definisce i colori delle fette della pie
  const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#FF4500', '#FFA500', '#ADFF2F', '#8A2BE2', '#00CED1', '#F08080', '#87CEEB'];

  useEffect(() => {
    const newUserColors = {};
    timbratureList.forEach(timbrature1 => {
      if (!newUserColors[timbrature1.createdBy]) {
        newUserColors[timbrature1.createdBy] = alternateWhiteAndGray();
      }
    });
    setUserColors(newUserColors);
  }, [timbratureList]);

  const handleMonthChange = e => {
    const month = parseInt(e.target.value, 10); // Ottieni il mese selezionato dal dropdown
    setSelectedMonth(month); // Imposta il nuovo mese selezionato
  };

  const handleYearChange = e => {
    const year = parseInt(e.target.value, 10); // Ottieni il mese selezionato dal dropdown
    setSelectedYear(year); // Imposta il nuovo mese selezionato
  };

  // calcolo ore attese per mese
  useEffect(() => {
    const hours = calculateExpectedWorkHours(selectedYear, selectedMonth);
    setExpectedHours(hours);
  }, [selectedYear, selectedMonth]);

  return (
    <div>
      <h2 id="timbrature-heading" data-cy="TimbratureHeading">
        Report ore consuntivate {/* mese di {selectedMonthLabel} - {selectedYear} */}
        <div className="d-flex justify-content-end"></div>
      </h2>
      <div className="d-flex justify-content-end"></div>
      <div className="d-flex justify-content-center mb-5">
        <select onChange={handleMonthChange} value={selectedMonth}>
          {months.map(month => (
            <option key={month.value} value={month.value}>
              {month.label}
            </option>
          ))}
        </select>
        <select onChange={handleYearChange} value={selectedYear}>
          {years.map(year => (
            <option key={year.value} value={year.value}>
              {year.label}
            </option>
          ))}
        </select>
        <div className="me-2 ps-2">
          <Button className="me-2 ps-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="timeRepApp.timbrature.home.refreshListLabel">Aggiorna</Translate>
          </Button>
          <Button className="ms-2" color="success" onClick={exportToExcel}>
            Esporta in Excel
          </Button>
        </div>
      </div>
      <Row>
        <Col sm="6" className="pe-4">
          <h5 style={{ margin: 0, marginBottom: 30, color: '#1D1E40', fontWeight: 'bold' }}>Conteggio ore per utente</h5>
          <p style={{ display: 'inline' }}>Giornate Attese per il mese corrente: </p>
          <p style={{ color: 'green', display: 'inline' }}>{expectedHours / 8}</p>
          <p style={{ display: 'inline' }}> - Ore Attese per il mese corrente: </p>
          <p style={{ color: 'green', display: 'inline' }}>{expectedHours}</p>
          <p></p>

          <Table responsive bordered hover className="shadow">
            <thead>
              <tr>
                <th className="hand">User</th>
                <th className="hand">Giornate Lavorate</th>
                <th className="hand">Ore Lavorate</th>
                <th className="hand">Ore Malattia</th>
                <th className="hand">Ore Ferie</th>
                <th className="hand">Totale</th>
              </tr>
            </thead>
            <tbody>
              {oreList.map &&
                oreList.map((oreItem, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td style={{ backgroundColor: userColors[oreItem.createdBy] }}>
                      <strong>{oreItem.createdBy}</strong>
                    </td>
                    <td style={{ backgroundColor: userColors[oreItem.createdBy] }}>{(oreList[i].totOre / 8).toFixed(1)}</td>
                    <td style={{ backgroundColor: userColors[oreItem.createdBy] }}>{oreList[i].totOre}</td>
                    <td style={{ backgroundColor: userColors[oreItem.createdBy] }}>{oreListMalattia[i] ? oreListMalattia[i].totOre : 0}</td>
                    <td style={{ backgroundColor: userColors[oreItem.createdBy] }}>{oreListFerie[i] ? oreListFerie[i].totOre : 0}</td>
                    <td
                      style={{
                        backgroundColor: userColors[oreItem.createdBy],
                        color:
                          oreItem.totOre +
                            (oreListMalattia[i] ? oreListMalattia[i].totOre : 0) +
                            (oreListFerie[i] ? oreListFerie[i].totOre : 0) !==
                          expectedHours
                            ? 'red'
                            : 'green',
                      }}
                    >
                      {oreItem.totOre +
                        (oreListMalattia[i] ? oreListMalattia[i].totOre : 0) +
                        (oreListFerie[i] ? oreListFerie[i].totOre : 0)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-end"></div>

          {totalItems ? <div className={timbratureList && timbratureList.length > 0 ? '' : 'd-none'}></div> : ''}
          <h5 style={{ margin: 0, marginBottom: 30, marginTop: 60, color: '#1D1E40', fontWeight: 'bold' }}>Dettaglio ore consuntivate</h5>
          <p>Report per singolo utente che definisce le ore consuntivate per progetto e giustificativo</p>
          <div className="table-responsive">
            {timbratureList && timbratureList.length > 0 ? (
              <Table responsive bordered hover className="shadow">
                <thead>
                  <tr>
                    <th className="hand">User</th>
                    <th className="hand">
                      <Translate contentKey="timeRepApp.timbrature.ore">Ore</Translate>{' '}
                    </th>
                    <th className="hand">
                      <Translate contentKey="timeRepApp.timbrature.progetti">Progetti</Translate>{' '}
                    </th>
                    <th className="hand">
                      <Translate contentKey="timeRepApp.timbrature.giustificativo">Giustificativo</Translate>{' '}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {timbratureList.map((timbrature3, z) => (
                    <tr key={`entity-${z}`} data-cy="entityTable" style={{ backgroundColor: userColors[timbrature3.createdBy] }}>
                      <td style={{ backgroundColor: userColors[timbrature3.createdBy] }}>
                        <strong>{timbrature3.createdBy}</strong>
                      </td>
                      <td style={{ backgroundColor: userColors[timbrature3.createdBy] }}>{timbrature3.totOre}</td>
                      <td style={{ backgroundColor: userColors[timbrature3.createdBy] }}>{timbrature3.titolo}</td>
                      <td style={{ backgroundColor: userColors[timbrature3.createdBy] }}>{timbrature3.giustificativo}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">Nessun report trovato per il mese impostato</div>
            )}
          </div>
        </Col>
        <Col sm="6" className="ps-4">
          <h5 style={{ color: '#1D1E40', fontWeight: 'bold', margin: 0, marginBottom: 30 }}>Ore per progetto</h5>
          <p>Ore totali consuntivate per singolo progetto nel mese corrente</p>

          <Table responsive bordered hover className="shadow" style={{ marginBottom: '50px' }}>
            <thead>
              <tr>
                <th className="hand">Progetto</th>
                <th className="hand">Ore</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((oreprog, y) => (
                  <tr key={`entity-${y}`} data-cy="entityTable" style={{ backgroundColor: y % 2 === 0 ? '#f4f4f4' : 'white' }}>
                    <td style={{ backgroundColor: y % 2 === 0 ? '#f4f4f4' : 'white' }}>{oreprog.name}</td>
                    <td style={{ backgroundColor: y % 2 === 0 ? '#f4f4f4' : 'white' }}>{oreprog.value}</td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <PieChart width={chartSize.width} height={chartSize.height}>
            <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={180} fill="#8884d8" label>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Legend verticalAlign="bottom" height={36} />
            <Tooltip />
          </PieChart>
        </Col>
      </Row>

      <Col sm="12"></Col>
    </div>
  );
};

export default ManagementTimbrature;

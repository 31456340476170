import dayjs from 'dayjs';
import { IProgetti } from 'app/shared/model/progetti.model';
import { IOreLavorate } from 'app/shared/model/oreLavorate.model';

export interface ITimbrature {
  id?: number;
  data?: dayjs.Dayjs | null;
  ore?: number | null;
  descrizione?: string | null;
  giustificativo?: string | null;
  progetti?: IProgetti | null;
}

export const defaultValue: Readonly<ITimbrature> = {};

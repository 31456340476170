import progetti from 'app/entities/progetti/progetti.reducer';
import clienti from 'app/entities/clienti/clienti.reducer';
import timbrature from 'app/management/timbrature/timbrature.reducer';
import orelavorate from 'app/management/timbrature/oreLavorate.reducer';
import oremalattia from 'app/management/timbrature/oreMalattia.reducer';
import oreferie from 'app/management/timbrature/oreFerie.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  progetti,
  clienti,
  timbrature,
  orelavorate,
  oremalattia,
  oreferie,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, Sidebar as ProSidebar } from 'react-pro-sidebar';
import { useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import './sidebar.css';

const Sidebar = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  let location = useLocation();

  const [isMobile, setIsMobile] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const updateDimensions = () => {
    if (window.innerWidth < 1300) {
      // 'xs' breakpoint
      setIsMobile(true);
      setCollapsed(true);
    } else {
      setIsMobile(false);
      setCollapsed(false);
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div style={{ display: 'flex', minHeight: 'calc(100vh - 110px)' }}>
      <ProSidebar collapsed={isMobile} backgroundColor="#fff">
        <Menu>
          {isAdmin ? (
            <>
              <MenuItem active={location.pathname.indexOf('/manage/timbrature') === 0}>
                <Link to="/manage/timbrature" className="menu-link">
                  <FontAwesomeIcon icon="chart-line" />
                  {!isMobile && <span style={{ marginLeft: '0.5em' }}>Dashboard</span>}
                </Link>
              </MenuItem>
              <MenuItem active={location.pathname.indexOf('/progetti') === 0}>
                <Link to="/progetti" className="menu-link">
                  <FontAwesomeIcon icon="list-check" />
                  {!isMobile && <span style={{ marginLeft: '0.5em' }}>Progetti</span>}
                </Link>
              </MenuItem>
              <MenuItem active={location.pathname.indexOf('/clienti') === 0}>
                <Link to="/clienti" className="menu-link">
                  <FontAwesomeIcon icon="users" />
                  {!isMobile && <span style={{ marginLeft: '0.5em' }}>Clienti</span>}
                </Link>
              </MenuItem>
              <MenuItem active={location.pathname.indexOf('/timbrature') === 0}>
                <Link to="/timbrature" className="menu-link">
                  <FontAwesomeIcon icon="stamp" />
                  {!isMobile && <span style={{ marginLeft: '0.5em' }}>Report</span>}
                </Link>
              </MenuItem>
            </>
          ) : (
            <MenuItem active={location.pathname.indexOf('/') === 0}>
              <Link to="/" className="menu-link">
                <FontAwesomeIcon icon="stamp" />
                {!isMobile && <span style={{ marginLeft: '0.5em' }}>Report</span>}
              </Link>
            </MenuItem>
          )}
        </Menu>
      </ProSidebar>
    </div>
  );
};

export default Sidebar;

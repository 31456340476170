import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Progetti from './progetti';
import Clienti from './clienti';
import Timbrature from './timbrature';
import ManagementTimbrature from 'app/management/timbrature/timbrature';

/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="progetti/*" element={<Progetti />} />
        <Route path="clienti/*" element={<Clienti />} />
        <Route path="timbrature/*" element={<Timbrature />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};

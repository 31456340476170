export const calculateExpectedWorkHours = (year, month) => {
  const isItalianHoliday = date => {
    const italianHolidays = [
      '01-01', // Capodanno
      '01-06', // Epifania
      '04-25', // Festa della Liberazione
      '05-01', // Festa del Lavoro
      '06-02', // Festa della Repubblica
      '08-15', // Ferragosto
      '11-01', // Ognissanti
      '12-08', // Immacolata Concezione
      '12-25', // Natale
      '12-26', // Santo Stefano
    ];

    const formattedDate = `${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    return italianHolidays.includes(formattedDate);
  };

  const firstDayOfMonth = new Date(year, month - 1, 1);
  const lastDayOfMonth = new Date(year, month, 0);
  let workdays = 0;

  for (let day = 1; day <= lastDayOfMonth.getDate(); day++) {
    const currentDate = new Date(year, month - 1, day);
    const dayOfWeek = currentDate.getDay(); // 0 = Domenica, 1 = Lunedì, ..., 5 = Venerdì, 6 = Sabato

    if (dayOfWeek >= 1 && dayOfWeek <= 5 && !isItalianHoliday(currentDate)) {
      // Se il giorno è da Lunedì a Venerdì e non è una festività
      workdays++;
    }
  }

  // Controlla se il Lunedì dell'Angelo cade nel mese
  const easterYear = year;
  const a = easterYear % 19;
  const b = Math.floor(easterYear / 100);
  const c = easterYear % 100;
  const d = Math.floor(b / 4);
  const e = b % 4;
  const f = Math.floor((b + 8) / 25);
  const g = Math.floor((b - f + 1) / 3);
  const h = (19 * a + b - d - g + 15) % 30;
  const i = Math.floor(c / 4);
  const k = c % 4;
  const l = (32 + 2 * e + 2 * i - h - k) % 7;
  const m = Math.floor((a + 11 * h + 22 * l) / 451);
  const n = (h + l - 7 * m + 114) % 31;
  const easterMonth = Math.floor((h + l - 7 * m + 114) / 31);
  const easterDay = n + 1;
  const easterDate = new Date(easterYear, easterMonth - 1, easterDay);

  const easterMonday = new Date(easterDate);
  easterMonday.setDate(easterMonday.getDate() + 1); // Lunedì dell'Angelo è il giorno dopo Pasqua

  if (easterMonday.getMonth() === month - 1) {
    // Se il Lunedì dell'Angelo è nel mese corrente, sottrai un giorno lavorativo
    workdays--;
  }

  return workdays * 8;
};

import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Clienti from './clienti';
import ClientiDetail from './clienti-detail';
import ClientiUpdate from './clienti-update';
import ClientiDeleteDialog from './clienti-delete-dialog';

const ClientiRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Clienti />} />
    <Route path="new" element={<ClientiUpdate />} />
    <Route path=":id">
      <Route index element={<ClientiDetail />} />
      <Route path="edit" element={<ClientiUpdate />} />
      <Route path="delete" element={<ClientiDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ClientiRoutes;

import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ITimbrature, defaultValue } from 'app/shared/model/timbrature.model';
import { IOreLavorate } from 'app/shared/model/oreLavorate.model';

const initialState: EntityState<IOreLavorate> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/management/timbrature';

// Actions

export const findOreLavorate = createAsyncThunk(
  'orelavorate/fetch_ore_lavorate',
  async ({ month, year }: IQueryParams) => {
    const requestUrl = `${apiUrl}/oreLavorate?month=${month}&year=${year}`;
    return axios.get<IOreLavorate[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const OreLavorateSlice = createEntitySlice({
  name: 'orelavorate',
  initialState,
  extraReducers(builder) {
    builder

      .addMatcher(isFulfilled(findOreLavorate), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(findOreLavorate), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = OreLavorateSlice.actions;

// Reducer
export default OreLavorateSlice.reducer;

import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Timbrature from './timbrature';
import TimbratureDetail from './timbrature-detail';
import TimbratureUpdate from './timbrature-update';
import TimbratureDeleteDialog from './timbrature-delete-dialog';

const TimbratureRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Timbrature />} />
    <Route path="new" element={<TimbratureUpdate />} />
    <Route path=":id">
      <Route index element={<TimbratureDetail />} />
      <Route path="edit" element={<TimbratureUpdate />} />
      <Route path="delete" element={<TimbratureDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TimbratureRoutes;

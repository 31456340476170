import dayjs from 'dayjs';
import { IClienti } from 'app/shared/model/clienti.model';

export interface IProgetti {
  id?: number;
  titolo?: string | null;
  dataInizioStimata?: dayjs.Dayjs | null;
  dataFineStimata?: dayjs.Dayjs | null;
  clienti?: IClienti | null;
}

export const defaultValue: Readonly<IProgetti> = {};

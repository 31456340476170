import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Loadable from 'react-loadable';
import LoginRedirect from 'app/modules/login/login-redirect';
import Logout from 'app/modules/login/logout';
import EntitiesRoutes from 'app/entities/routes';
import PrivateRoute, { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import ManagementEntitiesRoutes from 'app/management/routes';
import Timbrature from 'app/entities/timbrature';
import { useAppSelector } from 'app/config/store';

const loading = <div>loading ...</div>;

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        {/* <Route index element={<Home />} /> */}
        <Route path="logout" element={<Logout />} />

        {isAuthenticated ? (
          <>
            {isAdmin ? (
              <Route path="/" element={<Navigate to="/manage/timbrature" />} />
            ) : (
              <Route path="/" element={<Navigate to="/timbrature" />} />
            )}
          </>
        ) : (
          <Route path="oauth2/authorization/oidc" element={<LoginRedirect />} />
        )}
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route path="oauth2/authorization/oidc" element={<LoginRedirect />} />

        <Route
          path="manage/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <ManagementEntitiesRoutes />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />
        <Route path="timbrature/*" element={<Timbrature />} />
        <Route path="*" element={<Navigate to="/login" />} />
        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;

import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ITimbrature, defaultValue } from 'app/shared/model/timbrature.model';
import { IOreMalattia } from 'app/shared/model/oreMalattia.model';

const initialState: EntityState<IOreMalattia> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/management/timbrature';

// Actions

export const findOreMalattia = createAsyncThunk(
  'orelavorate/fetch_ore_Malattia',
  async ({ month, year }: IQueryParams) => {
    const requestUrl = `${apiUrl}/oreMalattia?month=${month}&year=${year}`;
    return axios.get<IOreMalattia[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const OreMalattiaSlice = createEntitySlice({
  name: 'oremalattia',
  initialState,
  extraReducers(builder) {
    builder

      .addMatcher(isFulfilled(findOreMalattia), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(findOreMalattia), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = OreMalattiaSlice.actions;

// Reducer
export default OreMalattiaSlice.reducer;

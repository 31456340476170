import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Timbrature from 'app/management/timbrature/timbrature';
import TimbratureDetail from 'app/management/timbrature/timbrature-detail';
import TimbratureUpdate from 'app/management/timbrature/timbrature-update';
import TimbratureDeleteDialog from 'app/management/timbrature/timbrature-delete-dialog';

const TimbratureRoutes = () => {
  return (
    <ErrorBoundaryRoutes>
      <Route index element={<Timbrature />} />
      <Route path="new" element={<TimbratureUpdate />} />
      <Route path=":id">
        <Route index element={<TimbratureDetail />} />
        <Route path="edit" element={<TimbratureUpdate />} />
        <Route path="delete" element={<TimbratureDeleteDialog />} />
      </Route>
    </ErrorBoundaryRoutes>
  );
};

export default TimbratureRoutes;
